/*** 
Author: Angelo Rossi 
Version: 1.0 
***/

/* Colour Variables */
$white: #fff;
$black: #000;
$grey: #666666;
$soft_grey: #85848D;
$lighter_grey: #8b8b8b;
$light_grey: #d9d8e1;
$very_light_grey: #f6f6f6;
$lightest_blue: #E6F4FF;
$light_blue: #95c9f4;
$inter_blue: #1266ab;
$dark_blue: #082f4f;

/* Font Variables */
$quicksand: 'Quicksand', sans-serif;

/* Font */
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,700');

/* Start */
html {
	font-family: $quicksand;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
    -o-text-size-adjust: 100%;
}

body {
	margin: 0;
	background-color: $white;
	font-family: $quicksand;
}

button {
	font-family: $quicksand;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
}

input {
	font-family: $quicksand;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	&:focus {
		outline: none;
		border-color: $inter_blue;
		transition: 0.3s;
	}
}

select {
	font-family: $quicksand;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
}

/* Header */
.header-container {
	height: 90vh;
	min-height: 500px;
	position: relative;
	overflow: hidden;
}

#wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	//background: #4568dc;
	background: linear-gradient(0.25turn, $dark_blue, $inter_blue);
	z-index: -1;
}

#particles-js { 
	position :absolute; 
	width: 100%; 
	height: 100%; 
	background-color: linear-gradient(0.25turn, $dark_blue, $inter_blue);
	background-image: url(""); 
	background-repeat: no-repeat; 
	background-size: cover; 
	background-position: center;
}

.navbar-container {
	position: fixed;
	width: 100%;
	min-height: 70px;
	margin: 0 0;
	border: 1px solid transparent;;
	z-index: 2;
	display: block;
	transition:all 0.3s;
	-moz-transition: all 0.3s;;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
}

.navbar {
	font-family: $quicksand;
	width: 60%;
	height: 90px;
	background-color: transparent;
	margin-left: auto;
	margin-right: auto;
	.navbar-brand {
		float: left;
		img {
			width: 35vh;
			padding: 1em 5%;
		}
		a {
			text-decoration: none;
			color: $white;
			margin: 0;
			h1 {
				margin: 27px 0;
				transition: 0.3s;
				margin-left: 25px;
				font-weight: 400;
			}
			img {
				width: 70px;
				float: left;
				padding-right: 0;
			}
		}
		&:hover {
			a {
				color: $light_blue;
				transition: 0.3s;
			}
		}
	}
}

nav {
	float: right;
	margin-top: 15px;
	ul {
		margin-top: 22px;
		transition: 0.2s;
		li {
			float: left;
			margin-right: 2em;
			list-style-type: none;
			&::after {
				content: '';
				display: block;
				width: 0;
				height: 1.5px;
				background: $light_blue;
				transition: width .4s;
			}
			&:hover {
				&::after {
					width: 100%;
				}
			}
			a {
				text-decoration: none;
				color: $white;
				font-size: 17px;
				&:hover {
					color: $light_blue;
					transition: 0.3s;
				}
			}
		}
	}
}

.menu-scroll {
	background-color: $white;
	color: $inter_blue;
	border-bottom: 1px solid $light_blue;
	transition: all 0.3s;
	-moz-transition: all 0.3s;;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
}

.text-color {
	color: $inter_blue !important;
}

.text-color:hover {
	color: $light_blue !important;
}

.burgernav {
	display: none;
	transition: all 0.3s;
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
}

.hero {
	height: 100%;
	//background-color: $inter_blue;
	z-index: 1;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-content: center;
}

.hero-content {
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: $quicksand;
	text-align: center;
	width: 100%;
	img {
		width: 100%;
	}
	.svg-a {
		width: 390px;
			.a {
				animation: aspin-animate 25s ease-in-out infinite;
				-webkit-animation: aspin-animate 25s ease-in-out infinite;
				-moz-animation: aspin-animate 25s ease-in-out infinite;
				position: absolute;
				transform-origin: center;
			}
			@keyframes aspin-animate {
				0% {
					transform: rotate(360deg);
				}
				10%{
					transform: rotate(0deg);
				}
				20% {
					transform: rotate(0deg);
				}
				40% {
					transform: rotate(0deg);
				}
				60% {
					transform: rotate(0deg);
				}
				80% {
					transform: rotate(0deg);
				}
				90% {
					transform: rotate(0deg);
				}
				95% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(-360deg);
				}
			}

			.circle {
				animation: circle-animate 25s ease-in-out infinite;
				-webkit-animation: circle-animate 25s ease-in-out infinite;
				-moz-animation: circle-animate 25s ease-in-out infinite;
				stroke-dashoffset: 1050;
				stroke-dasharray: 1050;
			}

			@keyframes circle-animate {
				0% {
					stroke-dashoffset: 1050;
				}
				10%{
					stroke-dashoffset: 0;
				}
				20% {
					stroke-dashoffset: 0;
				}
				40% {
					stroke-dashoffset: 0;
				}
				60% {
					stroke-dashoffset: 0;
				}
				80% {
					stroke-dashoffset: 0;
				}
				90% {
					stroke-dashoffset: 0;
				}
				100% {
					stroke-dashoffset: -1050;
				}
			}
		}
	}
	.web-design {
		display: flex;
		flex-direction: column;
		text-align: left;
		padding-bottom: 70px;
		padding-left: 25px;
		span{
			color: $white;
			font-size: 120px;
			height: 100px;
		}
}

.down-container {
	display: flex;
	justify-content: center;
	align-content: center;
	margin-top: -30px;
	z-index: 1;	
	.down-btn {
		background-color: $white;
		border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		z-index: 1;
	}
	.down {
		width: 70px;
		justify-content: center;
		align-items: center;
		display: flex;	
		color: $light_grey;
		height: 70px;
		transition: 0.2s ease-in-out;
		.down-arrow::before {
			content: '';
			margin: -2.5px;
			margin-top: -2.5px;
			margin-top: 6px;
			position: absolute;
			width: 4px;
			height: 4px;
			border-left: 1px solid $lighter_grey;
			border-bottom: 1px solid $lighter_grey;
			transform: rotate(-45deg);
		}
		.down-arrow::after {
			content: '';
			position: absolute;
			margin: -0.5px;
			margin-top: -18px;
			width: 1px;
			height: 29px;
			background-color: $lighter_grey;	
			animation: animate 2s linear infinite;		
			&:hover {
				border-left-color: $light_blue;
				border-bottom-color: $light_blue;
				transition: 0.2s ease-in-out;
			}
		}

		@keyframes animate {
			0% {
				transform-origin: top;
				transform: scaleY(0);
			}
			45% {
				transform-origin: top;
				transform: scaleY(1);
			}
			55% {
				transform-origin: bottom;
				transform: scaleY(1);
			}
			100% {
				transform-origin: bottom;
				transform: scaleY(0);
			}
		}
		@-webkit-keyframes animate {
			0% {
				transform-origin: top;
				transform: scaleY(0);
			}
			45% {
				transform-origin: top;
				transform: scaleY(1);
			}
			55% {
				transform-origin: bottom;
				transform: scaleY(1);
			}
			100% {
				transform-origin: bottom;
				transform: scaleY(0);
			}
		}
		@-moz-keyframes animate {
			0% {
				transform-origin: top;
				transform: scaleY(0);
			}
			45% {
				transform-origin: top;
				transform: scaleY(1);
			}
			55% {
				transform-origin: bottom;
				transform: scaleY(1);
			}
			100% {
				transform-origin: bottom;
				transform: scaleY(0);
			}
		}
	}
}

/* About */
.tagline {
	width: 100%;
	background-color: $white;
	padding: 5px 0 107px 0;
	h2 {
		text-align: center;
		font-weight: 400;
		font-size: 35px;
		margin-bottom: 4px;
		color: $inter_blue;	
	}
	p {
		width: 600px;
		max-width: 900px;
		margin: 0px auto;
		font-size: 23px;
		color: $grey;
		text-align: center;
		font-weight: 300;
		line-height: 40px;
	}
}

.about-container {
	width: 100%;
	background-color: $lightest_blue;
	overflow-x: hidden;
	.about {
		width: 100%;
		max-width: 850px;
		margin: 0px auto;
		.about-section {
			padding: 50px 0;
			width: 100%;
			border-bottom: 1px solid $inter_blue;
			display: flex;
			justify-content: center;
			align-items: center;
			&:nth-child(2) {
				.img-container {
					margin-left: 0;
					margin-right: 30px;
				}
			}
			&:nth-child(3) {
				border-bottom: 0;
			}
			.about-text {
				width: 75%;
				h2 {
					margin-bottom: 0;
					font-weight: 500;
					font-size: 30px;
					color: $inter_blue;
				}
				p {
					margin-top: 5px;
					line-height: 2.5;
				}
			}
			.img-container {
				height: 125px;
				width: 125px;
				background-color: $white;
				margin-left: 30px;
				overflow: hidden;
				border-radius: 50%;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				.icon-slider1 {
					position: relative;
					width: 625px;
					animation-name: web-icon-slider;
					animation-duration: 7s;
					animation-delay: 5s;
					animation-iteration-count: infinite;
					-webkit-animation-iteration-count: infinite;
					-moz-animation-iteration-count: infinite;
					animation-timing-function: cubic-bezier(1,0,0,1);
					-webkit-animation-timing-function: cubic-bezier(1,0,0,1);
					-moz-animation-timing-function: cubic-bezier(1,0,0,1);
					display: flex;
					justify-content: center;
					align-content: center;
					flex-direction: row;
				}
				img {
					width: 100%;	
				}

				@keyframes web-icon-slider {
					0% {
						left: 0;
					}
					12.5% {
						left: -120px;
					}	
					25% {
						left: -120px;
					}
					37.5% {
						left: -250px;
					}
					50% {
						left: -250px;
					}
					62.5%{
						left: -378px;
					}
					75% {
						left: -378px;
					}
					87.5% {
						left: -495px;
					}
					100% {
						left: -495px;
					}
				}
				@-webkit-keyframes web-icon-slider {
					0% {
						left: 0;
					}
					12.5% {
						left: -120px;
					}	
					25% {
						left: -120px;
					}
					37.5% {
						left: -250px;
					}
					50% {
						left: -250px;
					}
					62.5%{
						left: -378px;
					}
					75% {
						left: -378px;
					}
					87.5% {
						left: -495px;
					}
					100% {
						left: -495px;
					}
				}
				@-moz-keyframes web-icon-slider {
					0% {
						left: 0;
					}
					12.5% {
						left: -120px;
					}	
					25% {
						left: -120px;
					}
					37.5% {
						left: -250px;
					}
					50% {
						left: -250px;
					}
					62.5%{
						left: -378px;
					}
					75% {
						left: -378px;
					}
					87.5% {
						left: -495px;
					}
					100% {
						left: -495px;
					}
				}

			.icon-slider2 {
				position: relative;
				top: 0;
				left: -373px;
				width: 500px;
				animation-name: device-icon-slider;
				animation-duration: 7s;
				animation-delay: 7s;
				animation-iteration-count: infinite;
				-webkit-animation-iteration-count: infinite;
				-moz-animation-iteration-count: infinite;
				animation-timing-function: cubic-bezier(1,0,0,1);
				-webkit-animation-timing-function: cubic-bezier(1,0,0,1);
				-moz-animation-timing-function: cubic-bezier(1,0,0,1);
				display: flex;
				justify-content: center;
				align-content: center;
				flex-direction: row;
				}
				img {
					width: 100%;	
				}
	
				@keyframes device-icon-slider {
					0% {
						left: -373px;
					}
					12.5% {
						left: -237px;
					}
					25% {
						left: -237px;
					}
					37.5% {
						left: -117px;
					}
					50% {
						left: -117px;
					}
					62.5% {
						left: 2px;
					}
					75% {
						left: 2px;
					}
					87.5% {
						left: 2px;
					}
					100% {
						left: 2px;
					}
				}
			
				@-webkit-keyframes device-icon-slider {
					0% {
						left: -373px;
					}
					12.5% {
						left: -237px;
					}
					25% {
						left: -237px;
					}
					37.5% {
						left: -117px;
					}
					50% {
						left: -117px;
					}
					62.5% {
						left: 2px;
					}
					75% {
						left: 2px;
					}
					87.5% {
						left: 2px;
					}
					100% {
						left: 2px;
					}
				}
				@-moz-keyframes device-icon-slider {
					0% {
						left: -373px;
					}
					12.5% {
						left: -237px;
					}
					25% {
						left: -237px;
					}
					37.5% {
						left: -117px;
					}
					50% {
						left: -117px;
					}
					62.5% {
						left: 2px;
					}
					75% {
						left: 2px;
					}
					87.5% {
						left: 2px;
					}
					100% {
						left: 2px;
					}
				}

				.icon-slider3 {
				position: relative;
				width: 505px;
				animation-name: adobe-icon-slider;
				animation-duration: 7s;
				animation-delay: 9s;
				animation-iteration-count: infinite;
				-webkit-animation-iteration-count: infinite;
				-moz-animation-iteration-count: infinite;
				animation-timing-function: cubic-bezier(1,0,0,1);
				-webkit-animation-timing-function: cubic-bezier(1,0,0,1);
				-moz-animation-timing-function: cubic-bezier(1,0,0,1);
				display: flex;
				justify-content: center;
				align-content: center;
				flex-direction: row;
				}
	
				img {
					width: 100%;	
				}

				@keyframes adobe-icon-slider {
					0% {
						left: 0;
					}
					16.6% {
						left: -125px;
					}		
					33.2% {
						left: -125px;
					}
					49.8% {
						left: -252px;
					}
					66.4% {
						left: -252px;
					}
					83%{
						left: -373px;
					}
					100% {
						left: -373px;
					}
				}
				@-webkit-keyframes adobe-icon-slider {
					0% {
						left: 0;
					}
					16.6% {
						left: -125px;
					}		
					33.2% {
						left: -125px;
					}
					49.8% {
						left: -252px;
					}
					66.4% {
						left: -252px;
					}
					83%{
						left: -373px;
					}
					100% {
						left: -373px;
					}
				}
				@-moz-keyframes adobe-icon-slider {
					0% {
						left: 0;
					}
					16.6% {
						left: -125px;
					}		
					33.2% {
						left: -125px;
					}
					49.8% {
						left: -252px;
					}
					66.4% {
						left: -252px;
					}
					83%{
						left: -373px;
					}
					100% {
						left: -373px;
					}
				}
			}
			img {
				width: 100%;
			}
		}
	}			
}

/* Work */
.work {
	width: 100%;
	height: 100%;
    background-color: $very_light_grey;
	ul {
		display: flex;
		align-items: flex-end;
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

.geconcrete {
	flex: 1;
	background-color: $lighter_grey;
	a {
		display: block;
		position: relative;
		height: 53vh;
		text-align: center;
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		-ms-transform: translateZ(0);
		-o-transform: translateZ(0);
		transform: translateZ(0);
		img {
			position: absolute;
			display: block;
			max-width: 40%;
			top: 50%;
			left: 50%;
			-webkit-transition: .3s transform;
			-moz-transition: .3s transform;
			-ms-transition: .3s transform;
			-o-transition: .3s transform;
			transition: .3s transform;
			-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
			-ms-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%) translateZ(0);
		}
	}
	.coming-soon-overlay {
		transition: 0.2s ease-in-out;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
		background-color: $lighter_grey;
		&:hover {
			opacity: 1;
			transition: 0.2s ease-in-out;
		}
		.coming-soon-text {
			color: $grey;
			font-size: 75px;
			//font-weight: bold;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			text-align: center;
		}
	}
}

.gffl {
	flex: 1;
	background-color: $white;
	a {
		display: block;
		position: relative;
		height: 53vh;
		text-align: center;
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		-ms-transform: translateZ(0);
		-o-transform: translateZ(0);
		transform: translateZ(0);
		img {
			position: absolute;
			display: block;
			max-width: 70%;
			top: 50%;
			left: 50%;
			-webkit-transition: .3s transform;
			-moz-transition: .3s transform;
			-ms-transition: .3s transform;
			-o-transition: .3s transform;
			transition: .3s transform;
			-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
			-ms-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%) translateZ(0);
			&:hover {
				-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				-moz-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				-ms-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				-o-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				-webkit-transition: .3s transform;
				-moz-transition: .3s transform;
				-ms-transition: .3s transform;
				-o-transition: .3s transform;
				transition: .3s transform;
			}
		}
	}
}

.efpspc {
	flex: 1;
	background-color: #09558e;
	a {
		display: block;
		position: relative;
		height: 53vh;
		text-align: center;
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		-ms-transform: translateZ(0);
		-o-transform: translateZ(0);
		transform: translateZ(0);
		img {
			position: absolute;
			display: block;
			max-width: 60%;
			top: 50%;
			left: 50%;
			-webkit-transition: .3s transform;
			-moz-transition: .3s transform;
			-ms-transition: .3s transform;
			-o-transition: .3s transform;
			transition: .3s transform;
			-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
			-ms-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%) translateZ(0);
			&:hover {
				-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				-moz-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				-ms-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				-o-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
			}
		}
    }
}

.monet {
	flex: 1;
	background-color: #62291d;
	a {
		display: block;
		position: relative;
		height: 53vh;
		text-align: center;
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		-ms-transform: translateZ(0);
		-o-transform: translateZ(0);
		transform: translateZ(0);
		img {
			position: absolute;
			display: block;
			max-width: 70%;
			top: 50%;
			left: 50%;
			-webkit-transition: .3s transform;
			-moz-transition: .3s transform;
			-ms-transition: .3s transform;
			-o-transition: .3s transform;
			transition: .3s transform;
			-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
			-ms-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%) translateZ(0);
			&:hover {
				-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				-moz-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				-ms-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				-o-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
				transform: translateX(-50%) translateY(-50%) translateZ(0) scale(.95);
			}
		}
    }
}

/* Contact */
.contact {
	width: 100%;
	.form-container {
		margin: 0 auto;
		max-width: 700px;
		margin-top: 50px;
		h2 {
			text-align: center;
			font-weight: 400;
			font-size: 35px;
			margin-bottom: 0;
			color: $inter_blue;	
		}
		h3 {
			text-align: center;
			font-weight: 400;
			font-size: 25px;
			margin: 20px 0;
			color: $dark_blue;	
		}
		p {
			text-align: center;
			font-size: 15px;
			margin-bottom: 18px;
			color: $soft_grey;
		}
	}
}

.form {
	padding: 15px;
	padding-bottom: 60px;
}

input[type="text"] {
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	box-sizing: border-box;
	outline: 0;
	border-width: 0 0 1px 0;
	border-color: $black;
	background: none;
	transition: 0.3s;
    	&:focus {
		outline: none;
		border-color: $inter_blue;
		transition: 0.3s;
	}
}

input[type="email"] {
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	box-sizing: border-box;
	outline: 0;
	border-width: 0 0 1px 0;
	border-color: $black;
	background: none;
	transition: 0.3s;
    	&:focus {
		outline: none;
		border-color: $inter_blue;
		transition: 0.3s;
	}
}

input[type="tel"] {
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	box-sizing: border-box;
	outline: 0;
	border-width: 0 0 1px 0;
	border-color: $black;
	background: none;
    	&:focus {
		outline: none;
		border-color: $inter_blue;
		transition: 0.3s;
	}
}

textarea {
	font-family: $quicksand;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	width: 100%;
	padding: 12px 20px;
	margin: 8px 0;
	box-sizing: border-box;
	outline: 0;
	border-width: 0 0 1px 0;
	border-color: $black;
	resize: vertical;
	background: none;
	&:focus {
		outline: none;
		border-color: $inter_blue;
		transition: 0.3s;
	}
}

input[type="submit"] {
	background-color: transparent;
	font-size: 1em;
	padding: 10px 15px;
	font-weight: 300;
	margin-top: 15px;
	border: solid 1px #8b8b8b;
	transition: all 0.2s ease;
	color: $lighter_grey;
	&:hover {
		background-color: $inter_blue;
		border: solid 1px #1266ab;
		color: $white;
	}
}

input[type="reset"] {
	background-color: transparent;
	font-size: 1em;
	padding: 10px 15px;
	font-weight: 300;
	margin-top: 15px;
	border: solid 1px #8b8b8b;
	transition: all 0.2s ease;
	float: right;
	color: $lighter_grey;
	&:hover {
		background-color: $inter_blue;
		border: solid 1px #1266ab;
		color: $white;
	}
}

footer {
	width: 100%;
	height: 1vh;
	background-color: $lightest_blue;
	text-align: center;
	padding: 5vh 0;
	color: $inter_blue;
	p {
		margin: 0;
	}
}

/* Responsive/Mobile Friendly */
@media screen and (max-width: 1100px) {
	.navbar {
		width:80%;
	}
}

@media screen and (max-width: 950px) {
	.header-container {
		height: 85vh;
	}
	
	.navbar-container {
		border-left: 0;
	}

	.navbar {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
		height: 65px;
		.navbar-brand {
			a {
				h1 {
					margin: 4px 12px;
					font-size: 28px;
				}
			}
		}
	}
    
	nav {
		float: none;
		ul {
			float: left;
			overflow: hidden;
			text-align: center;
			width: 100%;
			background-color: $white;
			margin-bottom: 0;
			padding: 0;
			padding-top: 13px;
			display: none;
			transition: 0.2s;
			li {
				float: none;
				overflow: hidden;
				width: 100%;
				margin: 5px 0;
				height: 70px;
				a {
					display: block;
					padding-bottom: 8px;
					font-size: 27px;
					color: $inter_blue;
				}
				&:hover {
					&::after {
						width: 92.5%;
						margin-left: 15px;
					}
				}
			}
		}
	}
    
    .show {
		display: block;
    }
    
	.burgernav {
		color: $white;
		float: right;
		display: block;
		margin-right: 15px;
        margin-top: 7px;
		cursor: pointer;
		transition: 0.2s;
        -moz-transition: 0.2s;
         -webkit-transition: 0.2s;
         -o-transition: 0.2s;
		&:hover {
			color: $light_blue;
			transition: 0.2s;
             -moz-transition: 0.2s ease-in-out;
            -webkit-transition: 0.2s;
            -o-transition: 0.2s;
		}
        .line {
            width: 25px;
            height: 3px;
            background-color: $white;
            margin: 6px;
            display: block;
            transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			border-radius: 20px;
        }
	}

		.line-color {
			background-color: $inter_blue !important;
		}
		
    .burgernav.active{
        .line:nth-child(1) {
            transform: translateY(8px) rotate(225deg);
            -moz-transition: translateY(8px) rotate(225deg);
            -webkit-transition: translateY(8px) rotate(225deg);
            -o-transition: translateY(8px) rotate(225deg);
         }
        .line:nth-child(2) {
            opacity: 0; 
         }
        .line:nth-child(3) {
            transform: translateY(-10px) rotate(-225deg);
            -moz-transition: translateY(8px) rotate(-225deg);
            -webkit-transition: translateY(8px) rotate(-225deg);
            -o-transition: translateY(8px) rotate(-225deg);
        }
    }
    
    .hero-content {
		h1 {
			font-size: 47px;
            margin-top: 10px;
            padding-top: 35px;
            margin: 0 1.5%;
		}
		.svg-a {
			width: 300px;
		}
		.web-design {
			padding-right: 5px;
			span {
				font-size: 80px;
				height: 65px;
			}
		}
	}

	.tagline {
		padding: 25px 0 50px 0;
		h2 {
			margin-top: 0;
		}
		p {
			font-size: 22px;
			line-height: 32px;
		}
	}
    
	.about {
		height: 100%;
		margin-bottom: 20px;
	}
		
	.about-section {
		width: 90%;
		margin-top: 0;
		text-align: left;
		
		h1 {
			margin-bottom: 0;
		}
	}

	.about-imageholder {
		margin: 2px;
		float: left;
	}

	.work {
		height: 100%;
        ul {
            align-items: stretch;
            flex-direction: row;
        }
	}
    
	.geconcrete {
		a {
			height: 37.5vh;
			img {
				width: 60%;
				max-width: 60%;
			}
		}
	}

	.gffl {
		a {
			height: 37.5vh;
			img {
				width: 95%;
				max-width: 95%;
			}
		}
	}

	.efpspc {
		a {
			height: 37.5vh;
			img {
				width: 60%;
				max-width: 60%;
			}
		}
	}

	.monet {
		a {
			height: 37.5vh;
			img {
				width: 95%;
				max-width: 95%;
			}
		}
	}
    
	.contact {
		height: 550px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
		.form-container {
			margin-top: 25px;
			h2{
				font-size: 25px;
			}
			h3{
				font-size: 20px;
			}
			p {
				margin: 0 10px;
				font-size: 14px;
			}
		}
	}

	footer {
		margin-top: 60px;
		float: left;
	}
}

@media screen and (max-width: 800px) {
	.about-container {
		.about {
			display: flex;
			justify-content: center;
			align-content: center;
			flex-direction: column;
			.about-section {
			width: 94%;
			margin: 0 15px;
			text-align: center;
			display: flex;
			flex-direction: column;
				p {
					width: 100%;
				}
				h1 {
					margin-bottom: 0;
					text-align: center;
				}
				.img-container {
					margin: 0;
				}
				&:nth-child(2) .img-container {
					margin-right: 0px;
				}
			}
		}
	}
	.hero-content {
		.web-design {
			padding-bottom: 45px;
			padding-left: 0;
			span {
				font-size: 60px;
				height: 45px;
				padding-bottom: 5px;
				padding-left: 19px;
			}
		}
	}
	
}

@media screen and (max-width: 700px) {
	.about-section {
		width: 96%;
		margin-top: 0;
        margin-left: 1.5%;
		text-align: left;
		
		p{
			width: 80%;
		}

		h1 {
			margin-bottom: 0;
		}
	}


	.tagline {
		padding-top: 20px;
		h2 {
			font-size: 25px;
		}
		p {
			width: 60%;
			font-size: 18px;
		}
	}
}


@media screen and (max-width: 600px) {
	.about-section {
		width: 96%;
		margin-top: 0;
        margin-left: 1.5%;
		text-align: left;
		
		p{
			width: 70%;
			float: right;
		}

		h1 {
			margin-bottom: 0;
		}
	}

	.about-section-last {
		width: 96%;
		margin-top: 0;
		margin-left: 1.5%;
		text-align: left;
		p{
			width: 70%;
			float: right;
		}

		h1 {
			margin-bottom: 0;
		}
	}

	.hero-content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		.svg-a {
			width: 220px;
		}
		.web-design {
			padding-bottom: 5px;
			padding-left: 0;
			span {
				font-size: 60px;
				height: 45px;
				padding-bottom: 5px;
				padding-left: 19px;
			}
		}
	}

	.contact {
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 550px) {
	.work {
		height: 100%;
        ul {
            align-items: stretch;
            flex-direction: column;
        }
	}

	.form {
		padding-bottom: 0;
	}
}

@media screen and (max-width: 400px) {
	.about-section {
		width: 96%;
		margin-top: 0;
        margin-left: 1.5%;
		text-align: left;
		
		p{
			width: 65%;
		}

		h1 {
			margin-bottom: 0;
		}
	}

	.hero-content {
		.web-design {
			padding-bottom: 45px;
			padding-left: 0;
		}
		span {
			font-size: 50px;
			height: 35px;
			padding-bottom: 5px;
			padding-left: 19px;
		}
	}

	.about-container {
		.about {
			.about-section {
				width: 92%;
			}
		}
	}
}

@media screen and (max-width: 350px) {
	.about-section {
		width: 96%;
		margin-top: 0;
        margin-left: 1.5%;
		text-align: left;
		
		p{
			width: 60%;
			float: right;
		}

		h1 {
			margin-bottom: 0;
		}
	}

	.about-section-last {
		width: 96%;
		margin-top: 0;
		margin-left: 1.5%;
		text-align: left;
		p{
			width: 60%;
			float: right;
		}
		h1 {
			margin-bottom: 0;
		}
	}
}