/*** 
Author: Angelo Rossi 
Version: 1.0 
***/
/* Colour Variables */
/* Font Variables */
/* Font */
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,700");
/* Start */
html {
  font-family: "Quicksand", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
}

body {
  margin: 0;
  background-color: #fff;
  font-family: "Quicksand", sans-serif;
}

button {
  font-family: "Quicksand", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

input {
  font-family: "Quicksand", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

input:focus {
  outline: none;
  border-color: #1266ab;
  transition: 0.3s;
}

select {
  font-family: "Quicksand", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

/* Header */
.header-container {
  height: 90vh;
  min-height: 500px;
  position: relative;
  overflow: hidden;
}

#wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0.25turn, #082f4f, #1266ab);
  z-index: -1;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: linear-gradient(0.25turn, #082f4f, #1266ab);
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.navbar-container {
  position: fixed;
  width: 100%;
  min-height: 70px;
  margin: 0 0;
  border: 1px solid transparent;
  z-index: 2;
  display: block;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.navbar {
  font-family: "Quicksand", sans-serif;
  width: 60%;
  height: 90px;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
}

.navbar .navbar-brand {
  float: left;
}

.navbar .navbar-brand img {
  width: 35vh;
  padding: 1em 5%;
}

.navbar .navbar-brand a {
  text-decoration: none;
  color: #fff;
  margin: 0;
}

.navbar .navbar-brand a h1 {
  margin: 27px 0;
  transition: 0.3s;
  margin-left: 25px;
  font-weight: 400;
}

.navbar .navbar-brand a img {
  width: 70px;
  float: left;
  padding-right: 0;
}

.navbar .navbar-brand:hover a {
  color: #95c9f4;
  transition: 0.3s;
}

nav {
  float: right;
  margin-top: 15px;
}

nav ul {
  margin-top: 22px;
  transition: 0.2s;
}

nav ul li {
  float: left;
  margin-right: 2em;
  list-style-type: none;
}

nav ul li::after {
  content: '';
  display: block;
  width: 0;
  height: 1.5px;
  background: #95c9f4;
  transition: width .4s;
}

nav ul li:hover::after {
  width: 100%;
}

nav ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 17px;
}

nav ul li a:hover {
  color: #95c9f4;
  transition: 0.3s;
}

.menu-scroll {
  background-color: #fff;
  color: #1266ab;
  border-bottom: 1px solid #95c9f4;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.text-color {
  color: #1266ab !important;
}

.text-color:hover {
  color: #95c9f4 !important;
}

.burgernav {
  display: none;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.hero {
  height: 100%;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
}

.hero-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  width: 100%;
}

.hero-content img {
  width: 100%;
}

.hero-content .svg-a {
  width: 390px;
}

.hero-content .svg-a .a {
  animation: aspin-animate 25s ease-in-out infinite;
  -webkit-animation: aspin-animate 25s ease-in-out infinite;
  -moz-animation: aspin-animate 25s ease-in-out infinite;
  position: absolute;
  transform-origin: center;
}

@keyframes aspin-animate {
  0% {
    transform: rotate(360deg);
  }
  10% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(0deg);
  }
  95% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.hero-content .svg-a .circle {
  animation: circle-animate 25s ease-in-out infinite;
  -webkit-animation: circle-animate 25s ease-in-out infinite;
  -moz-animation: circle-animate 25s ease-in-out infinite;
  stroke-dashoffset: 1050;
  stroke-dasharray: 1050;
}

@keyframes circle-animate {
  0% {
    stroke-dashoffset: 1050;
  }
  10% {
    stroke-dashoffset: 0;
  }
  20% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 0;
  }
  60% {
    stroke-dashoffset: 0;
  }
  80% {
    stroke-dashoffset: 0;
  }
  90% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -1050;
  }
}

.web-design {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 70px;
  padding-left: 25px;
}

.web-design span {
  color: #fff;
  font-size: 120px;
  height: 100px;
}

.down-container {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: -30px;
  z-index: 1;
}

.down-container .down-btn {
  background-color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  z-index: 1;
}

.down-container .down {
  width: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #d9d8e1;
  height: 70px;
  transition: 0.2s ease-in-out;
}

.down-container .down .down-arrow::before {
  content: '';
  margin: -2.5px;
  margin-top: -2.5px;
  margin-top: 6px;
  position: absolute;
  width: 4px;
  height: 4px;
  border-left: 1px solid #8b8b8b;
  border-bottom: 1px solid #8b8b8b;
  transform: rotate(-45deg);
}

.down-container .down .down-arrow::after {
  content: '';
  position: absolute;
  margin: -0.5px;
  margin-top: -18px;
  width: 1px;
  height: 29px;
  background-color: #8b8b8b;
  animation: animate 2s linear infinite;
}

.down-container .down .down-arrow::after:hover {
  border-left-color: #95c9f4;
  border-bottom-color: #95c9f4;
  transition: 0.2s ease-in-out;
}

@keyframes animate {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  45% {
    transform-origin: top;
    transform: scaleY(1);
  }
  55% {
    transform-origin: bottom;
    transform: scaleY(1);
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

@-webkit-keyframes animate {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  45% {
    transform-origin: top;
    transform: scaleY(1);
  }
  55% {
    transform-origin: bottom;
    transform: scaleY(1);
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

@-moz-keyframes animate {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  45% {
    transform-origin: top;
    transform: scaleY(1);
  }
  55% {
    transform-origin: bottom;
    transform: scaleY(1);
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

/* About */
.tagline {
  width: 100%;
  background-color: #fff;
  padding: 5px 0 107px 0;
}

.tagline h2 {
  text-align: center;
  font-weight: 400;
  font-size: 35px;
  margin-bottom: 4px;
  color: #1266ab;
}

.tagline p {
  width: 600px;
  max-width: 900px;
  margin: 0px auto;
  font-size: 23px;
  color: #666666;
  text-align: center;
  font-weight: 300;
  line-height: 40px;
}

.about-container {
  width: 100%;
  background-color: #E6F4FF;
  overflow-x: hidden;
}

.about-container .about {
  width: 100%;
  max-width: 850px;
  margin: 0px auto;
}

.about-container .about .about-section {
  padding: 50px 0;
  width: 100%;
  border-bottom: 1px solid #1266ab;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-container .about .about-section:nth-child(2) .img-container {
  margin-left: 0;
  margin-right: 30px;
}

.about-container .about .about-section:nth-child(3) {
  border-bottom: 0;
}

.about-container .about .about-section .about-text {
  width: 75%;
}

.about-container .about .about-section .about-text h2 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 30px;
  color: #1266ab;
}

.about-container .about .about-section .about-text p {
  margin-top: 5px;
  line-height: 2.5;
}

.about-container .about .about-section .img-container {
  height: 125px;
  width: 125px;
  background-color: #fff;
  margin-left: 30px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.about-container .about .about-section .img-container .icon-slider1 {
  position: relative;
  width: 625px;
  animation-name: web-icon-slider;
  animation-duration: 7s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -moz-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}

.about-container .about .about-section .img-container img {
  width: 100%;
}

@keyframes web-icon-slider {
  0% {
    left: 0;
  }
  12.5% {
    left: -120px;
  }
  25% {
    left: -120px;
  }
  37.5% {
    left: -250px;
  }
  50% {
    left: -250px;
  }
  62.5% {
    left: -378px;
  }
  75% {
    left: -378px;
  }
  87.5% {
    left: -495px;
  }
  100% {
    left: -495px;
  }
}

@-webkit-keyframes web-icon-slider {
  0% {
    left: 0;
  }
  12.5% {
    left: -120px;
  }
  25% {
    left: -120px;
  }
  37.5% {
    left: -250px;
  }
  50% {
    left: -250px;
  }
  62.5% {
    left: -378px;
  }
  75% {
    left: -378px;
  }
  87.5% {
    left: -495px;
  }
  100% {
    left: -495px;
  }
}

@-moz-keyframes web-icon-slider {
  0% {
    left: 0;
  }
  12.5% {
    left: -120px;
  }
  25% {
    left: -120px;
  }
  37.5% {
    left: -250px;
  }
  50% {
    left: -250px;
  }
  62.5% {
    left: -378px;
  }
  75% {
    left: -378px;
  }
  87.5% {
    left: -495px;
  }
  100% {
    left: -495px;
  }
}

.about-container .about .about-section .img-container .icon-slider2 {
  position: relative;
  top: 0;
  left: -373px;
  width: 500px;
  animation-name: device-icon-slider;
  animation-duration: 7s;
  animation-delay: 7s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -moz-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}

.about-container .about .about-section .img-container img {
  width: 100%;
}

@keyframes device-icon-slider {
  0% {
    left: -373px;
  }
  12.5% {
    left: -237px;
  }
  25% {
    left: -237px;
  }
  37.5% {
    left: -117px;
  }
  50% {
    left: -117px;
  }
  62.5% {
    left: 2px;
  }
  75% {
    left: 2px;
  }
  87.5% {
    left: 2px;
  }
  100% {
    left: 2px;
  }
}

@-webkit-keyframes device-icon-slider {
  0% {
    left: -373px;
  }
  12.5% {
    left: -237px;
  }
  25% {
    left: -237px;
  }
  37.5% {
    left: -117px;
  }
  50% {
    left: -117px;
  }
  62.5% {
    left: 2px;
  }
  75% {
    left: 2px;
  }
  87.5% {
    left: 2px;
  }
  100% {
    left: 2px;
  }
}

@-moz-keyframes device-icon-slider {
  0% {
    left: -373px;
  }
  12.5% {
    left: -237px;
  }
  25% {
    left: -237px;
  }
  37.5% {
    left: -117px;
  }
  50% {
    left: -117px;
  }
  62.5% {
    left: 2px;
  }
  75% {
    left: 2px;
  }
  87.5% {
    left: 2px;
  }
  100% {
    left: 2px;
  }
}

.about-container .about .about-section .img-container .icon-slider3 {
  position: relative;
  width: 505px;
  animation-name: adobe-icon-slider;
  animation-duration: 7s;
  animation-delay: 9s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -moz-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}

.about-container .about .about-section .img-container img {
  width: 100%;
}

@keyframes adobe-icon-slider {
  0% {
    left: 0;
  }
  16.6% {
    left: -125px;
  }
  33.2% {
    left: -125px;
  }
  49.8% {
    left: -252px;
  }
  66.4% {
    left: -252px;
  }
  83% {
    left: -373px;
  }
  100% {
    left: -373px;
  }
}

@-webkit-keyframes adobe-icon-slider {
  0% {
    left: 0;
  }
  16.6% {
    left: -125px;
  }
  33.2% {
    left: -125px;
  }
  49.8% {
    left: -252px;
  }
  66.4% {
    left: -252px;
  }
  83% {
    left: -373px;
  }
  100% {
    left: -373px;
  }
}

@-moz-keyframes adobe-icon-slider {
  0% {
    left: 0;
  }
  16.6% {
    left: -125px;
  }
  33.2% {
    left: -125px;
  }
  49.8% {
    left: -252px;
  }
  66.4% {
    left: -252px;
  }
  83% {
    left: -373px;
  }
  100% {
    left: -373px;
  }
}

.about-container .about .about-section img {
  width: 100%;
}

/* Work */
.work {
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
}

.work ul {
  display: flex;
  align-items: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.geconcrete {
  flex: 1;
  background-color: #8b8b8b;
}

.geconcrete a {
  display: block;
  position: relative;
  height: 53vh;
  text-align: center;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.geconcrete a img {
  position: absolute;
  display: block;
  max-width: 40%;
  top: 50%;
  left: 50%;
  -webkit-transition: .3s transform;
  -moz-transition: .3s transform;
  -ms-transition: .3s transform;
  -o-transition: .3s transform;
  transition: .3s transform;
  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%) translateZ(0);
}

.geconcrete .coming-soon-overlay {
  transition: 0.2s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: #8b8b8b;
}

.geconcrete .coming-soon-overlay:hover {
  opacity: 1;
  transition: 0.2s ease-in-out;
}

.geconcrete .coming-soon-overlay .coming-soon-text {
  color: #666666;
  font-size: 75px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.gffl {
  flex: 1;
  background-color: #fff;
}

.gffl a {
  display: block;
  position: relative;
  height: 53vh;
  text-align: center;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.gffl a img {
  position: absolute;
  display: block;
  max-width: 70%;
  top: 50%;
  left: 50%;
  -webkit-transition: .3s transform;
  -moz-transition: .3s transform;
  -ms-transition: .3s transform;
  -o-transition: .3s transform;
  transition: .3s transform;
  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%) translateZ(0);
}

.gffl a img:hover {
  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  -moz-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  -ms-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  -o-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  -webkit-transition: .3s transform;
  -moz-transition: .3s transform;
  -ms-transition: .3s transform;
  -o-transition: .3s transform;
  transition: .3s transform;
}

.efpspc {
  flex: 1;
  background-color: #09558e;
}

.efpspc a {
  display: block;
  position: relative;
  height: 53vh;
  text-align: center;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.efpspc a img {
  position: absolute;
  display: block;
  max-width: 60%;
  top: 50%;
  left: 50%;
  -webkit-transition: .3s transform;
  -moz-transition: .3s transform;
  -ms-transition: .3s transform;
  -o-transition: .3s transform;
  transition: .3s transform;
  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%) translateZ(0);
}

.efpspc a img:hover {
  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  -moz-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  -ms-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  -o-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
}

.monet {
  flex: 1;
  background-color: #62291d;
}

.monet a {
  display: block;
  position: relative;
  height: 53vh;
  text-align: center;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.monet a img {
  position: absolute;
  display: block;
  max-width: 70%;
  top: 50%;
  left: 50%;
  -webkit-transition: .3s transform;
  -moz-transition: .3s transform;
  -ms-transition: .3s transform;
  -o-transition: .3s transform;
  transition: .3s transform;
  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%) translateZ(0);
}

.monet a img:hover {
  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  -moz-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  -ms-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  -o-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0.95);
}

/* Contact */
.contact {
  width: 100%;
}

.contact .form-container {
  margin: 0 auto;
  max-width: 700px;
  margin-top: 50px;
}

.contact .form-container h2 {
  text-align: center;
  font-weight: 400;
  font-size: 35px;
  margin-bottom: 0;
  color: #1266ab;
}

.contact .form-container h3 {
  text-align: center;
  font-weight: 400;
  font-size: 25px;
  margin: 20px 0;
  color: #082f4f;
}

.contact .form-container p {
  text-align: center;
  font-size: 15px;
  margin-bottom: 18px;
  color: #85848D;
}

.form {
  padding: 15px;
  padding-bottom: 60px;
}

input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  outline: 0;
  border-width: 0 0 1px 0;
  border-color: #000;
  background: none;
  transition: 0.3s;
}

input[type="text"]:focus {
  outline: none;
  border-color: #1266ab;
  transition: 0.3s;
}

input[type="email"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  outline: 0;
  border-width: 0 0 1px 0;
  border-color: #000;
  background: none;
  transition: 0.3s;
}

input[type="email"]:focus {
  outline: none;
  border-color: #1266ab;
  transition: 0.3s;
}

input[type="tel"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  outline: 0;
  border-width: 0 0 1px 0;
  border-color: #000;
  background: none;
}

input[type="tel"]:focus {
  outline: none;
  border-color: #1266ab;
  transition: 0.3s;
}

textarea {
  font-family: "Quicksand", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  outline: 0;
  border-width: 0 0 1px 0;
  border-color: #000;
  resize: vertical;
  background: none;
}

textarea:focus {
  outline: none;
  border-color: #1266ab;
  transition: 0.3s;
}

input[type="submit"] {
  background-color: transparent;
  font-size: 1em;
  padding: 10px 15px;
  font-weight: 300;
  margin-top: 15px;
  border: solid 1px #8b8b8b;
  transition: all 0.2s ease;
  color: #8b8b8b;
}

input[type="submit"]:hover {
  background-color: #1266ab;
  border: solid 1px #1266ab;
  color: #fff;
}

input[type="reset"] {
  background-color: transparent;
  font-size: 1em;
  padding: 10px 15px;
  font-weight: 300;
  margin-top: 15px;
  border: solid 1px #8b8b8b;
  transition: all 0.2s ease;
  float: right;
  color: #8b8b8b;
}

input[type="reset"]:hover {
  background-color: #1266ab;
  border: solid 1px #1266ab;
  color: #fff;
}

footer {
  width: 100%;
  height: 1vh;
  background-color: #E6F4FF;
  text-align: center;
  padding: 5vh 0;
  color: #1266ab;
}

footer p {
  margin: 0;
}

/* Responsive/Mobile Friendly */
@media screen and (max-width: 1100px) {
  .navbar {
    width: 80%;
  }
}

@media screen and (max-width: 950px) {
  .header-container {
    height: 85vh;
  }
  .navbar-container {
    border-left: 0;
  }
  .navbar {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: 65px;
  }
  .navbar .navbar-brand a h1 {
    margin: 4px 12px;
    font-size: 28px;
  }
  nav {
    float: none;
  }
  nav ul {
    float: left;
    overflow: hidden;
    text-align: center;
    width: 100%;
    background-color: #fff;
    margin-bottom: 0;
    padding: 0;
    padding-top: 13px;
    display: none;
    transition: 0.2s;
  }
  nav ul li {
    float: none;
    overflow: hidden;
    width: 100%;
    margin: 5px 0;
    height: 70px;
  }
  nav ul li a {
    display: block;
    padding-bottom: 8px;
    font-size: 27px;
    color: #1266ab;
  }
  nav ul li:hover::after {
    width: 92.5%;
    margin-left: 15px;
  }
  .show {
    display: block;
  }
  .burgernav {
    color: #fff;
    float: right;
    display: block;
    margin-right: 15px;
    margin-top: 7px;
    cursor: pointer;
    transition: 0.2s;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
  }
  .burgernav:hover {
    color: #95c9f4;
    transition: 0.2s;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
  }
  .burgernav .line {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 6px;
    display: block;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    border-radius: 20px;
  }
  .line-color {
    background-color: #1266ab !important;
  }
  .burgernav.active .line:nth-child(1) {
    transform: translateY(8px) rotate(225deg);
    -moz-transition: translateY(8px) rotate(225deg);
    -webkit-transition: translateY(8px) rotate(225deg);
    -o-transition: translateY(8px) rotate(225deg);
  }
  .burgernav.active .line:nth-child(2) {
    opacity: 0;
  }
  .burgernav.active .line:nth-child(3) {
    transform: translateY(-10px) rotate(-225deg);
    -moz-transition: translateY(8px) rotate(-225deg);
    -webkit-transition: translateY(8px) rotate(-225deg);
    -o-transition: translateY(8px) rotate(-225deg);
  }
  .hero-content h1 {
    font-size: 47px;
    margin-top: 10px;
    padding-top: 35px;
    margin: 0 1.5%;
  }
  .hero-content .svg-a {
    width: 300px;
  }
  .hero-content .web-design {
    padding-right: 5px;
  }
  .hero-content .web-design span {
    font-size: 80px;
    height: 65px;
  }
  .tagline {
    padding: 25px 0 50px 0;
  }
  .tagline h2 {
    margin-top: 0;
  }
  .tagline p {
    font-size: 22px;
    line-height: 32px;
  }
  .about {
    height: 100%;
    margin-bottom: 20px;
  }
  .about-section {
    width: 90%;
    margin-top: 0;
    text-align: left;
  }
  .about-section h1 {
    margin-bottom: 0;
  }
  .about-imageholder {
    margin: 2px;
    float: left;
  }
  .work {
    height: 100%;
  }
  .work ul {
    align-items: stretch;
    flex-direction: row;
  }
  .geconcrete a {
    height: 37.5vh;
  }
  .geconcrete a img {
    width: 60%;
    max-width: 60%;
  }
  .gffl a {
    height: 37.5vh;
  }
  .gffl a img {
    width: 95%;
    max-width: 95%;
  }
  .efpspc a {
    height: 37.5vh;
  }
  .efpspc a img {
    width: 60%;
    max-width: 60%;
  }
  .monet a {
    height: 37.5vh;
  }
  .monet a img {
    width: 95%;
    max-width: 95%;
  }
  .contact {
    height: 550px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .contact .form-container {
    margin-top: 25px;
  }
  .contact .form-container h2 {
    font-size: 25px;
  }
  .contact .form-container h3 {
    font-size: 20px;
  }
  .contact .form-container p {
    margin: 0 10px;
    font-size: 14px;
  }
  footer {
    margin-top: 60px;
    float: left;
  }
}

@media screen and (max-width: 800px) {
  .about-container .about {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  .about-container .about .about-section {
    width: 94%;
    margin: 0 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .about-container .about .about-section p {
    width: 100%;
  }
  .about-container .about .about-section h1 {
    margin-bottom: 0;
    text-align: center;
  }
  .about-container .about .about-section .img-container {
    margin: 0;
  }
  .about-container .about .about-section:nth-child(2) .img-container {
    margin-right: 0px;
  }
  .hero-content .web-design {
    padding-bottom: 45px;
    padding-left: 0;
  }
  .hero-content .web-design span {
    font-size: 60px;
    height: 45px;
    padding-bottom: 5px;
    padding-left: 19px;
  }
}

@media screen and (max-width: 700px) {
  .about-section {
    width: 96%;
    margin-top: 0;
    margin-left: 1.5%;
    text-align: left;
  }
  .about-section p {
    width: 80%;
  }
  .about-section h1 {
    margin-bottom: 0;
  }
  .tagline {
    padding-top: 20px;
  }
  .tagline h2 {
    font-size: 25px;
  }
  .tagline p {
    width: 60%;
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .about-section {
    width: 96%;
    margin-top: 0;
    margin-left: 1.5%;
    text-align: left;
  }
  .about-section p {
    width: 70%;
    float: right;
  }
  .about-section h1 {
    margin-bottom: 0;
  }
  .about-section-last {
    width: 96%;
    margin-top: 0;
    margin-left: 1.5%;
    text-align: left;
  }
  .about-section-last p {
    width: 70%;
    float: right;
  }
  .about-section-last h1 {
    margin-bottom: 0;
  }
  .hero-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .hero-content .svg-a {
    width: 220px;
  }
  .hero-content .web-design {
    padding-bottom: 5px;
    padding-left: 0;
  }
  .hero-content .web-design span {
    font-size: 60px;
    height: 45px;
    padding-bottom: 5px;
    padding-left: 19px;
  }
  .contact {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 550px) {
  .work {
    height: 100%;
  }
  .work ul {
    align-items: stretch;
    flex-direction: column;
  }
  .form {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 400px) {
  .about-section {
    width: 96%;
    margin-top: 0;
    margin-left: 1.5%;
    text-align: left;
  }
  .about-section p {
    width: 65%;
  }
  .about-section h1 {
    margin-bottom: 0;
  }
  .hero-content .web-design {
    padding-bottom: 45px;
    padding-left: 0;
  }
  .hero-content span {
    font-size: 50px;
    height: 35px;
    padding-bottom: 5px;
    padding-left: 19px;
  }
  .about-container .about .about-section {
    width: 92%;
  }
}

@media screen and (max-width: 350px) {
  .about-section {
    width: 96%;
    margin-top: 0;
    margin-left: 1.5%;
    text-align: left;
  }
  .about-section p {
    width: 60%;
    float: right;
  }
  .about-section h1 {
    margin-bottom: 0;
  }
  .about-section-last {
    width: 96%;
    margin-top: 0;
    margin-left: 1.5%;
    text-align: left;
  }
  .about-section-last p {
    width: 60%;
    float: right;
  }
  .about-section-last h1 {
    margin-bottom: 0;
  }
}
